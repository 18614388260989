export default {
  "modal": "cjJ",
  "container": "cjs",
  "form-container": "cjF",
  "preview-container": "cjg",
  "preview-content": "cjD",
  "switch-container": "cjV",
  "supplier-invoice-preview": "cjL",
  "header": "cjT",
  "footer": "cjz",
  "form-content": "cja",
  "form-content-section": "cjH",
  "form-disclaimer": "cjO",
  "hidden": "cjm"
};
