export default {
  "row": "qll",
  "item-bulk": "qlX",
  "active": "qlC",
  "disabled": "qlk",
  "cell": "qlJ body-2",
  "not-disabled": "qls",
  "item-amount-disabled": "qlF",
  "item-amount-credit": "qlg",
  "no-padding": "qlD",
  "cell-content": "qlV",
  "centered-cell": "qlL",
  "item-counterparty-icon": "qlT mr-16",
  "avatar": "qlz",
  "item-counterparty-name": "qla",
  "item-counterparty-name-status": "qlH",
  "disputing-chargeback": "qlO",
  "item-counterparty-name-text": "qlm",
  "item-amount": "qlp body-1",
  "item-amount-foreign": "qlx",
  "attachment-icon": "qlw",
  "item-method": "qlN",
  "bank-account-avatar": "qlv",
  "attachment-status-dot": "qlo",
  "attached": "qln",
  "missing": "qli",
  "item-attachment": "qlA",
  "compact": "qlY",
  "upsell-badge": "qlh",
  "fadein-item": "qlf"
};
