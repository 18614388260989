export default {
  "supplier-selector-wrapper": "qqw",
  "select-supplier": "qqN",
  "dropdown": "qqv",
  "iban": "qqo",
  "card": "qqn body-2",
  "card-content": "qqi",
  "add-btn": "qqA btn btn--square btn--icon-only",
  "archived-card": "qqY",
  "title": "qqh body-1",
  "archived-title": "qqf",
  "edit-supplier-tooltip": "qqK",
  "add-supplier": "qqG btn btn--tertiary",
  "error": "qqr caption",
  "form-title": "qqb title2",
  "dropdown-menu": "qqy",
  "supplier-invoice-detail-menu": "qqU",
  "item-divider": "qqj",
  "menu-item": "qqW body-2 dropdown-overlay",
  "disabled": "qqu",
  "iban-error": "qZS"
};
