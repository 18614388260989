export default {
  "wrapper": "qXI",
  "title": "qXt",
  "activity-tag": "qXM",
  "select": "qXP",
  "tooltip": "qXl",
  "date-picker": "qXX",
  "internal-notes": "qXC",
  "scheduled": "qXk",
  "payroll-selector-wrapper": "qXJ"
};
