export default {
  "row": "qlK",
  "col": "qlG",
  "header": "qlr",
  "block": "qlb",
  "col-5": "qly",
  "col-6": "qlU",
  "col-9": "qlj",
  "col-12": "qlW",
  "hide": "qlu"
};
