export default {
  "beneficiary-details": "qsR",
  "beneficiary-account-types": "qsQ",
  "divider": "qse",
  "label": "qsB",
  "labels": "qsE",
  "error": "qsd",
  "link": "qsI caption",
  "sticky-panel": "qst"
};
