export default {
  "review": "cUz",
  "review-content": "cUa",
  "review-parameters": "cUH",
  "row": "cUO",
  "row__border": "cUm",
  "label": "cUp",
  "value": "cUx",
  "error": "cUw",
  "tooltip-icon": "cUN",
  "terms-link": "cUv"
};
