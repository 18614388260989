export default {
  "row": "qJA body-1",
  "active": "qJY",
  "cell": "qJh",
  "empty": "qJf",
  "ellipsis": "qJK",
  "text-secondary": "qJG",
  "cell-type": "qJr",
  "cell-reason": "qJb",
  "cell-amount": "qJy",
  "row-sidebar": "qJU",
  "animated": "qJj",
  "fadein-item": "qJW",
  "animated-cell": "qJu"
};
