export default {
  "label-select": "cuP",
  "puce": "cul",
  "puce-wrapper": "cuX",
  "puce-container": "cuC",
  "readonly": "cuk",
  "label-display": "cuJ",
  "label-list-name": "cus body-1",
  "label-list-value": "cuF body-2"
};
