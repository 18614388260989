export default {
  "transfer-form": "qCL",
  "transfer-form-title": "qCT",
  "transfer-form-subtitle": "qCz",
  "form": "qCa",
  "form-footer": "qCH",
  "footer-btn": "qCO",
  "header": "qCm",
  "main-settings": "qCp",
  "additional-settings": "qCx",
  "form-container": "qCw"
};
