export default {
  "header-cell": "qkm",
  "empty": "qkp",
  "header-type": "qkx",
  "header-requester": "qkw",
  "header-amount": "qkN",
  "header-approval": "qkv",
  "header-content": "qko caption-bold",
  "active": "qkn",
  "row-compact": "qki",
  "row-sidebar": "qkA",
  "hidden": "qkY",
  "animated": "qkh",
  "fadein-item": "qkf",
  "animated-cell": "qkK"
};
