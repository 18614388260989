/* import __COLOCATED_TEMPLATE__ from './supplier-selector.hbs'; */
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import RSVP from 'rsvp';

import { Disclaimer } from '@repo/design-system-kit';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

// @ts-expect-error
import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';
import { DEBOUNCE_MS } from 'qonto/constants/timers';

interface SupplierSelectorSignature {
  // The arguments accepted by the component
  Args: {
    currency: string;
    hasAttemptedToSubmit: boolean;
    hideInlineDisclaimer: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class SupplierSelector extends Component<SupplierSelectorSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  // @ts-expect-error
  @tracked defaultOptions;
  @tracked selectDifferentSupplier = false;

  @service declare intl: Services['intl'];
  @service declare store: Services['store'];

  get options() {
    // @ts-expect-error
    return this.defaultOptions || this.args.fetchSuppliersTask?.lastSuccessful?.value || [];
  }

  get filteredOptions() {
    // @ts-expect-error
    let options = this.options.filter(({ status }) => status !== SUPPLIER_STATUSES.ARCHIVED);

    if (!options.length) return this.defaultOptions;

    if (
      this.selectedSupplier &&
      this.selectedSupplier === SUPPLIER_STATUSES.UNARCHIVED &&
      // @ts-expect-error
      !options.some(({ id }) => id === this.selectedSupplier?.id)
    ) {
      return [...options, this.selectedSupplier];
    }

    return options;
  }

  get selectedSupplier() {
    // @ts-expect-error
    let snapshot = this.args.invoice.supplierSnapshot;
    return { ...snapshot, tinNumber: snapshot?.tin };
  }

  get dropdownSelectedValue() {
    if (this.isArchived) {
      return `${this.selectedSupplier?.name} ${this.intl.t('supplier-invoices.supplier-invoice-details.supplier-information.archived-supplier-label')}`;
    }

    return this.selectedSupplier?.name;
  }

  get isArchived() {
    return this.selectedSupplier?.status === SUPPLIER_STATUSES.ARCHIVED;
  }

  get shouldShowSelector() {
    if (!variation('feature--boolean-improve-invoice-payment-flows')) return true;

    let didNotSelectSupplier = !this.selectedSupplier?.id;

    return didNotSelectSupplier || this.selectDifferentSupplier;
  }

  get shouldShowCard() {
    if (!variation('feature--boolean-improve-invoice-payment-flows')) return true;

    return !this.selectDifferentSupplier;
  }

  get showMissingIbanDisclaimer() {
    return (
      !this.args.hideInlineDisclaimer &&
      variation('feature--boolean-improve-invoice-payment-flows') &&
      !this.selectedSupplier?.iban &&
      this.args.currency === 'EUR'
    );
  }

  get getDisclaimerLevel() {
    return this.args.hasAttemptedToSubmit ? 'error' : 'warning';
  }

  get hasIbanError() {
    return this.args.hasAttemptedToSubmit && !this.selectedSupplier?.iban;
  }

  get invoiceCurrency() {
    return { invoiceCurrency: this.args.currency };
  }
  // @ts-expect-error
  searchSuppliers(query, resolve, reject) {
    return (
      // @ts-expect-error
      this.args.fetchSuppliersTask
        .perform(query)
        // @ts-expect-error
        .catch(error => reject(error))
        // @ts-expect-error
        .then(res => resolve(res))
    );
  }

  // @ts-expect-error
  @action handleSupplierSearch(query) {
    if (!this.defaultOptions) {
      this.defaultOptions = this.filteredOptions;
    }

    return new RSVP.Promise((resolve, reject) => {
      debounce(this, this.searchSuppliers, query, resolve, reject, DEBOUNCE_MS);
    });
  }

  // @ts-expect-error
  @action updateSupplier({ id, iban, name, tinNumber, email }) {
    // @ts-expect-error
    this.args.updateSupplier('supplierSnapshot', { id, name, iban, tin: tinNumber, email });
    // @ts-expect-error
    this.args.updateSupplier('supplierName', name);
    // @ts-expect-error
    this.args.invoice.supplierId = id;
    // @ts-expect-error
    this.args.invoice.supplierSnapshot = {
      id,
      name,
      iban,
      tin: tinNumber,
      email,
    };

    this.selectDifferentSupplier = false;
  }

  @action changeSupplier() {
    this.selectDifferentSupplier = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SupplierInvoices::EditForm::SupplierSelector': typeof SupplierSelector;
  }
}
