export default {
  "transfer-header": "qPY",
  "infobox": "qPh header-section",
  "proof-section": "qPf",
  "proof-link": "qPK",
  "download-icon": "qPG",
  "cancel-button": "qPr",
  "request-refund-button": "qPb",
  "refund-requested-indicator": "qPy",
  "recall-indicator": "qPU",
  "payment-purpose": "qPj",
  "payment-purpose-label": "qPW",
  "repeat-infobox": "qPu qPh header-section",
  "additional-infobox": "qlS qPh header-section",
  "repeat-transfer-action": "qlc",
  "financing-not-compliant-disclaimer": "qlq"
};
