export default {
  "details": "qcD",
  "details-header": "qcV",
  "details-header-amount": "qcL",
  "details-content": "qcT",
  "details-footer": "qcz",
  "invoice-status-container": "qca body-2",
  "invoice-dates": "qcH",
  "overdue": "qcO",
  "details-list": "qcm",
  "details-list-item": "qcp",
  "details-list-item-label": "qcx body-2",
  "details-list-item-value": "qcw",
  "border-top": "qcN",
  "description": "qcv",
  "description-value": "qco",
  "edit-button": "qcn body-2",
  "edit-icon": "qci",
  "badge-union": "qcA",
  "edit-description": "qcY",
  "amount-disclaimer": "qch",
  "strike-amount": "qcf"
};
