export default {
  "sidebar-content": "qXT",
  "section": "qXz",
  "header-wrapper": "qXa",
  "transfer-id": "qXH caption",
  "small-padding": "qXO",
  "title": "qXm",
  "subtitle": "qXp",
  "actions": "qXx"
};
