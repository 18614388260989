export default {
  "wrapper": "cjZ",
  "content": "cjR",
  "title": "cjQ",
  "aside": "cje",
  "description": "cjB",
  "faq-link": "cjE",
  "close-btn": "cjd",
  "container": "cjI",
  "mandate-field-list": "cjt",
  "mandate-field-item": "cjM",
  "mandate-field-item__dot": "cjP",
  "mandate-field-item__text": "cjl"
};
