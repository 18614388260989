export default {
  "vat-lines": "qgH",
  "vat-line": "qgO",
  "vat-input": "qgm",
  "vat-rate": "qgp",
  "vat-option-button": "qgx",
  "disabled": "qgw",
  "vat-option-tooltip": "qgN",
  "remove-label": "qgv",
  "remove-button": "qgo",
  "vat-total": "qgn",
  "vat-total-amount": "qgi",
  "add-vat-line": "qgA",
  "with-vat-lines": "qgY"
};
