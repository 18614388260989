export default {
  "header-cell": "qlQ",
  "header-content": "qle caption-bold",
  "active": "qlB",
  "align-right": "qlE",
  "empty": "qld",
  "align-checkbox": "qlI",
  "col-5": "qlt",
  "col-9": "qlM",
  "emitted-at-header-tag": "qlP"
};
