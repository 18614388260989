export default {
  "card-header": "qMY",
  "counterparty-name": "qMh",
  "header-section": "qMf",
  "pan-section": "qMK header-section body-2",
  "infobox": "qMG header-section",
  "see-card-button": "qMr",
  "card-identifier-section": "qMb",
  "card-icon": "qMy",
  "nickname": "qMU body-1",
  "pan": "qMj body-2"
};
