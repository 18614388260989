export default {
  "card": "qFb",
  "card-primary": "qFy",
  "content": "qFU",
  "caption-text": "qFj",
  "content-primary": "qFW",
  "disabled": "qFu",
  "illustration": "qgS",
  "illustration-primary": "qgc",
  "illustration-disabled": "qgq",
  "cta": "qgZ",
  "dismiss-button": "qgR",
  "buttons": "qgQ",
  "required-badge": "qge"
};
