export default {
  "wrapper": "qXo",
  "ember-power-select-dropdown": "qXn",
  "status": "qXi body-1",
  "error": "qXA",
  "success": "qXY",
  "type-container": "qXh",
  "type-close": "qXf",
  "date": "qXK",
  "close-icon": "qXG",
  "container": "qXr",
  "container-picto": "qXb",
  "container-picto-status": "qXy",
  "general": "qXU",
  "general-amount": "qXj",
  "unprocessed": "qXW",
  "fx-rate": "qXu",
  "general-counterparty": "qCS body-2",
  "infobox": "qCc",
  "account-infobox": "qCq qCc",
  "initiator-infobox": "qCZ qCc",
  "transaction-infobox": "qCR qCc",
  "repeat-infobox": "qCQ qCc",
  "item": "qCe body-2",
  "eye-icon": "qCB",
  "repeat-transfer-action": "qCE",
  "disclaimer": "qCd",
  "start-verification-link": "qCI",
  "financing-not-compliant-disclaimer": "qCt"
};
