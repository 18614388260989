export default {
  "header": "qSz",
  "subtitle": "qSa body-2",
  "date": "qSH",
  "subtitle-error": "qSO",
  "card": "qSm",
  "card-header": "qSp",
  "line-placeholder": "qSx",
  "post-closing-list": "qSw",
  "post-closing-container": "qSN",
  "post-closing-list-element": "qSv",
  "free-trial-disclaimer": "qSo",
  "description": "qSn body-2",
  "mw-100": "qSi",
  "addon-overview": "qSA",
  "addons-container": "qSY"
};
