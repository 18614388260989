/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { IN_DISPUTE_STATUS } from 'qonto/constants/transactions';
import { Amount } from 'qonto/react/components/transactions/sidebar/header/amount/amount';
import { Counterparty } from 'qonto/react/components/transactions/sidebar/header/counterparty';
import { Date } from 'qonto/react/components/transactions/sidebar/header/date';
import { Type } from 'qonto/react/components/transactions/sidebar/header/type';
import { SidebarHeaderCardDisclaimer } from 'qonto/react/transactions/components/sidebar/header/card/disclaimer';

interface TransactionsSidebarHeaderCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransactionsSidebarHeaderCard extends Component<TransactionsSidebarHeaderCardSignature> {
  amount = Amount;
  Counterparty = Counterparty;
  Date = Date;
  disclaimer = SidebarHeaderCardDisclaimer;
  type = Type;

  @service declare abilities: Services['abilities'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];

  get showLocalAmount() {
    // @ts-expect-error
    let { highlightedItem: transaction } = this.args;

    return (
      transaction.localAmount > 0 && transaction.amountCurrency !== transaction.localAmountCurrency
    );
  }

  get headerStatus() {
    // @ts-expect-error
    let { highlightedItem: transaction } = this.args;

    // 'disputing' is not a real transaction status, but it's used
    // to show that a Chargeback claim is in progress for that transaction
    if (transaction.chargebackDisputingTypes?.length > 0) {
      return IN_DISPUTE_STATUS;
    }

    return transaction.status;
  }

  get cardImageUri() {
    // @ts-expect-error
    let cardDesign = this.args.highlightedItem.subject.get('cardDesign');
    if (cardDesign) {
      return `/illustrations/cards/card-${cardDesign}.svg`;
    }
    return null;
  }

  @action
  onClickCardPreview() {
    // Using aria-disabled doesn't prevent click event from being triggered
    if (this.abilities.cannot('read card')) return;

    this.segment.track('transaction_panel_card_link_clicked');
    this.router.transitionTo('cards.index', {
      queryParams: {
        // @ts-expect-error
        highlight: this.args.highlightedItem.subject.get('id'),
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Transactions::Sidebar::Header::Card': typeof TransactionsSidebarHeaderCard;
  }
}
