export default {
  "attachment-sidebar": "qcP",
  "sidebar-scroll": "qcl",
  "top-section": "qcX",
  "timeline-section": "qcC",
  "sidebar-header": "qck",
  "matched-section": "qcJ",
  "collapsible-timeline": "qcs",
  "timeline-status-label": "qcF",
  "m-24": "qcg"
};
