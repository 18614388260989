export default {
  "row": "qkG body-1",
  "active": "qkr",
  "cell": "qkb",
  "empty": "qky",
  "ellipsis": "qkU",
  "text-secondary": "qkj",
  "transfer-type": "qkW",
  "transfer-type__icon": "qku",
  "quick-actions": "qJS",
  "amount": "qJc",
  "cell-approve": "qJq",
  "cell-type": "qJZ",
  "cell-requester": "qJR",
  "account-select": "qJQ",
  "row-compact": "qJe",
  "row-sidebar": "qJB",
  "cell-amount": "qJE",
  "hidden": "qJd",
  "animated": "qJI",
  "fadein-item": "qJt",
  "animated-cell": "qJM"
};
