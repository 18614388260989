export default {
  "savings-account": "cyK",
  "closed": "cyG",
  "badge": "cyr",
  "subtitle": "cyb",
  "amount": "cyy",
  "processing": "cyU",
  "gain": "cyj",
  "active": "cyW",
  "progress": "cyu",
  "progress-bar": "cUS",
  "progress-text": "cUc",
  "info": "cUq",
  "menu-item": "cUZ body-2",
  "close-account": "cUR"
};
