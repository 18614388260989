export default {
  "fileupload": "qFN",
  "file": "qFv",
  "label": "qFo",
  "zone": "qFn",
  "error": "qFi",
  "hide": "qFA",
  "dropzone": "qFY",
  "hidden": "qFh",
  "upload-icon": "qFf",
  "dropping": "qFK",
  "bounce2": "qFG",
  "error-message": "qFr"
};
