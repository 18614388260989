export default {
  "row": "cux",
  "disabled": "cuw",
  "cell": "cuN body-2",
  "cell-link": "cuv",
  "cell-content": "cuo",
  "item-truncate-text": "cun",
  "item-name-cell": "cui",
  "item-account-cell": "cuA",
  "icon": "cuY",
  "icon-download": "cuh"
};
