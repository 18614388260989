export default {
  "invoice-sidebar": "cWQ",
  "body": "cWe",
  "box": "cWB",
  "box-header": "cWE",
  "sidebar-box": "cWd",
  "border-top": "cWI",
  "row": "cWt",
  "greyed-out": "cWM",
  "strike-through": "cWP"
};
