export default {
  "display-block": "qsh",
  "pay-later-tooltip": "qsf",
  "disabled": "qsK",
  "stepper": "qsG",
  "installments": "qsr",
  "flex-items": "qsb",
  "day-label": "qsy body-2",
  "installment-amount": "qsU body-1",
  "spacing": "qsj",
  "info-icon": "qsW",
  "detail": "qsu",
  "detail-value": "qFS",
  "extra": "qFc",
  "extra-value": "qFq",
  "badge": "qFZ",
  "title": "qFR",
  "link": "qFQ"
};
