export default {
  "container": "qFs",
  "container-column": "qFF",
  "content-wrapper": "qFg",
  "media-wrapper": "qFD",
  "content": "qFV",
  "title": "qFL",
  "subtitle": "qFT",
  "content-slot": "qFz",
  "sticky-panel": "qFa",
  "sticky-actions": "qFH"
};
