export default {
  "container": "qgP",
  "cards-container": "qgl",
  "card": "qgX",
  "expanded": "qgC",
  "hidden": "qgk",
  "collapsed": "qgJ",
  "with-expand-collapse-animation": "qgs",
  "with-dismiss-animation": "qgF",
  "has-one-card": "qgg",
  "dismissing": "qgD",
  "with-hover-animation": "qgV",
  "toggle-button": "qgL",
  "toggle-container": "qgT",
  "toggle-button-icon": "qgz",
  "has-two-cards": "qga"
};
