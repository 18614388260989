export default {
  "sidebar-header": "qMW",
  "sidebar-header__top": "qMu",
  "sidebar-header__body": "qPS",
  "sidebar-header__content": "qPc",
  "sidebar-header__date": "qPq",
  "infobox": "qPZ",
  "infobox--link": "qPR",
  "ghost": "qPQ",
  "fail": "qPe",
  "r-transaction-disclaimer": "qPB",
  "hold-disclaimer": "qPE",
  "transaction-link": "qPd body-2",
  "icon-link": "qPI"
};
