export default {
  "form": "qqM",
  "form-title": "qqP title2",
  "row": "qql",
  "col": "qqX",
  "actions": "qqC",
  "buttons": "qqk",
  "collapsible-timeline": "qqJ",
  "link": "qqs",
  "timeline-status-label": "qqF",
  "section": "qqg",
  "einvoicing-disclaimer": "qqD",
  "disclaimer-section": "qqV qqg",
  "related-documents-card-margin-top": "qqL",
  "my-24": "qqT"
};
